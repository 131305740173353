.css-1869usk-MuiFormControl-root{
    display: flex !important;
    flex-direction: row  !important;
}
.dashboardfilterfields{
    position: relative;
    margin: 5px 10px;
    padding: 5px;
}
.reportsfilterfields{
    position: relative;
    margin: 5px 20px;
    padding: 5px;
}
.filterscontainer{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
    flex-wrap: wrap;
}
.react-minimal-datetime-range__range-input-wrapper{
    padding: 9px 0 !important;
}
.react-minimal-datetime-range__range .react-minimal-datetime-range{
    position: relative;
}
.reportsfilterscontainer{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}
.react-minimal-datetime-range__range .react-minimal-datetime-range {
    position: relative !important;
}
.react-minimal-datetime-date-piker {
    position: relative !important;
}
.react-minimal-datetime-range__range-input-wrapper input.react-minimal-datetime-range__range-input {
    width: 30% !important;
}
.rdrDateDisplayWrapper{
    width: 300px !important;
}