#reports-lists {
    margin: 30px;
    text-align: center;
  }
  
  #table-row {
    display: table-cell;
    background-color: #1c226b;
    color: #ffffff;
    padding: 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500px;
    font-size: 13px;
    line-height: 1.5rem;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  .css-dwuj3p-MuiTableCell-root {
    width: 400px;
    font-weight: bold;
  }

  .btn {
    background-color: #1c226b !important;
    font-size: 12px !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    padding: 0px 6px !important;
  }