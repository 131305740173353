/* .spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: 40vh;
  }
  .spinner:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid rgb(0, 0, 0);
    border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
    animation: spinner 1.2s linear infinite;
  } */

  .spinner {
    width: 48px;
    margin-top: 40vh;
    height: 48px;
    border: 5px solid #1c226b;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spinner 1.2s linear infinite;
    }
    
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  