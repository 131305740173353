.dashboard-filters-container {
    margin: 55px 10px 10px 10px !important;
    text-align: center;
}

.react-minimal-datetime-range__range .react-minimal-datetime-range {
    position: absolute;
}

.rdrDateDisplayWrapper {
    background-color: #ffffff;
    margin: 10;
    padding: 20;
}

.dashboard-table{
    border:1px solid #dee2e6 !important;
}
.dashboard-cell{
    padding: 6px !important;
}
.dashboard-left-cell{
    width: 75% !important;
    font-weight: 600 !important;
    font-size: 12px !important;
}
.dashboard-right-cell{
    font-size: 12px !important;
    text-align: center !important;
}
