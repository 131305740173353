/* .css-viou3o-MuiAutocomplete-root {
    width: 95% !important;
    margin: 0px 10px;
} */
.table-sports {
    margin: 10px;
}
#headingtxt{
    font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
    /* font-size: 25px; */
    color: #1c226b;
    /* font-weight: 500; */
    position: relative;
    margin:30px 0px 10px 0px;
}