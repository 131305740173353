.login-page {
  top: 0px;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #0e1136;
  flex-direction: column;
  justify-content: center;
  float: left;
}
.logincontainer {
  height: 95%;
  display: flex;
  flex-direction: column;
  background-color: #1c226b;
  align-items: center;
  padding: 17px;
}

.formholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoimage {
  width: 80px;
  height: 80px;
  padding: 10px;
  z-index: 10;
  position: relative;
  user-select: none;
}
#txt1 {
  color: white;
  font-weight: 500;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input:invalid {
  box-shadow: none;
  width: 230px;
  padding: 0 14px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 34px;
}

.loginbtn {
  height: 35px;
  background-color: green;
  width: 16.7rem;
  outline: none;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: whitesmoke;
  /* margin-top: 1.3rem; */
  border-radius: 4px;
}

#inputlabels {
  color: #ffffff;
  font: 12px Roboto, Arial, Helvetica, sans-serif;
  text-align: left;
  margin: 8px 0px 0px;
  outline: none;
}

#inputboxe1 {
  border-radius: 5px;
  width: 240px;
}
#inputboxe2 {
  border-radius: 5px;
  width: 240px;
}

.css-1x5jdmq{
  padding: 10.5px 14px !important;
}

.forget-passwd{
  margin: 0px 0px;
  text-align: left;
  color: white;
  cursor: pointer;
  font-size: 12px;
}

.forget-passwd:hover{
  color: #4dabf7;
}
.back-to-login{
  margin: 10px 0px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  /* font-size: 12px; */
}
.back-to-login:hover{
  color: #4dabf7;
}
.error{
  margin: 0px;
  color: #ff0000;
  font-size: 12px;
  text-align: left;
}

.css-k4qjio-MuiFormHelperText-root{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.css-hjvdj6{
  margin-left: 0px !important;
  margin-right: 0px !important;
}