.markets-lists {
    color: black;
    padding: 20px;
  }
  
  .market-textfield {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  #table-row {
    display: table-cell;
    background-color: #1c226b;
    color: #ffffff;
    padding: 15px;
  }
  
  .formLabel {
    margin: 10px !important;
  }
  
  .inputFields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  
  .submit-markbtn {
      text-align: center;
  }
  /* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    height: 55px !important;
  } */