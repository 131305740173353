
/* .css-hip9hq-MuiPaper-root-MuiAppBar-root{
    background-color:#1c226b !important;
} */
.pages{
    text-decoration: none;
    color: white;
    padding: 0px 17px 0px 0px;
    font-size: 13px;
}
#btns{
    text-decoration: none;
    list-style: none;
    text-align: center;
}
#menu-appbar{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.css-1t6c9ts{
    padding-left: 25px !important;
}