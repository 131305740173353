.dropzone-container {
  /* max-height: 450px; */
  margin-top: 20px !important;
}
.dropzone {
  border: 2px dashed #64aaff;
  background-color: #ecf9ff;
  border-radius: 5px;
  /* padding: 20px 0px 20px 0px; */
  text-align: center;
  /* margin: 20px 20px 20px 20px; */
  /* max-height: 100%; */
  margin-left: 20px;
  height: 86%;
}

.dropzone p {
  font-size: 18px;
  margin-bottom: 10px;
}

.dropzone ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropzone li {
  margin-bottom: 10px;
}
.ondrop {
  cursor: pointer;
  padding: 0 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tablezone {
  /* padding: 20px 0px 20px 0px; */
  text-align: center;
  margin: 20px 20px 20px 20px;
  max-height: 100%;
}
.card-grid {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
.table-dropzon {
  margin-right: 20px;
  height: 100%;
  padding-right: 5px;
  overflow: scroll;
}
.image-name:hover {
  overflow: visible;
}
